<template>
  <mu-card-header style="display: flex;align-items:center" :title="userinfo.username" :sub-title="userinfo.mobile">
    <mu-avatar slot="avatar">
      <img :src="userinfo.userhead || defaultHead" />
    </mu-avatar>
    <div class="operate" v-if="operate">
      <div @click="options()">
        <img src="@/assets/image/option.png" alt="">
      </div>
      <div @click="exits()">
        <img src="@/assets/image/exit.png" alt="">
      </div>
    </div>
  </mu-card-header>
</template>
<script>
import head from '@/assets/image/headImage.png'
import store from "store";
export default {
  props: {
    operate: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      defaultHead: head,
      userinfo: {
        userhead: '',
        username: '',
        mobile: '',
      },
    };
  },
  methods: {
    options() {
      this.$emit('optionicon')
    },
    exits() {
      this.$emit('exiticon')
    },
  },
  mounted() {
    this.userinfo.username = store.get('adminuser').adminuser.realName
    this.userinfo.userhead = store.get('adminuser').adminuser.headImage
    this.userinfo.mobile = store.get('adminuser').adminuser.mobile
  },
}
</script>
<style lang="scss" scoped>
.mu-card-title {
  color: #C7D5F2;
}

.mu-card-sub-title {
  color: #626A8F
}

.mu-card-header {
  position: relative;

  .operate {
    position: absolute;
    right: 0;

    img {
      width: .4389rem;
      height: .4389rem;
    }

    div {
      display: inline-block;
      margin-left: .4083rem;
    }
  }
}
</style>
