/**
 * 接口域名的管理
 */
// 正式配置
// const base = {
//
//   //sq:'/api',//请求代理需要用到
//     sq:'http://wechat.nxptdn.com',
//     bd: 'http://operator.nxptdn.com',
//     client:'http://e.nxptdn.com',
//     appid:'wx9e5e67da26c51a14'
// }

// 测试配置
const base = {
      // sq: '/api',//请求代理需要用到
      //sq:'http://test-wechat.nxptdn.com',
      // bd: 'http://192.168.14.2:9090',
      // bd: "https://app-api.hehaokeji.com/",
      bd: "http://zhonglan_test.tianhecloud.com:54721/",
      // client: 'http://zhonglan_test.tianhecloud.com:54723/',
      client: 'http://zlgzh.tianhecloud.com/',
      appid: 'wxc0db52167e547f73'
}

// 开发配置
// const base = {
//   sq: '/api',//请求代理需要用到
//   //sq:'http://test-wechat.nxptdn.com',
//   // bd: 'http://localhost:9090',
//   // bd: "https://app-api.hehaokeji.com/",
//   bd: "http://192.168.14.2:9090/",
//   client: 'http://e.hehaokeji.com',
//   appid: 'wx62eb4439eb634ca8'
// }


// const base = {
//     sq: '/api',
//     // sq:'http://202.85.220.170:8866/electric-website',   http://test-wechat.nxptdn.com/
//     bd: '/api'
// }


export default base;


